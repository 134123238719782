import { Nullable } from '@crossbeam/types';

export type BittsIconPrefix = 'fas' | 'far' | 'fad' | 'fak';
export type BittsIcon = [BittsIconPrefix, string] | null;
export type BittsClasses = string[] | Record<string, string> | string | null;

/* These types can be used wholesale, or excluded, see: https://www.typescriptlang.org/docs/handbook/utility-types.html#excludeuniontype-excludedmembers */
export const BittsSizeMap = {
  Tiny: 'tiny',
  XSmall: 'x-small',
  Small: 'small',
  Medium: 'medium',
  Large: 'large',
  XLarge: 'x-large',
} as const;
export type BittsSize = (typeof BittsSizeMap)[keyof typeof BittsSizeMap];

export type BittsColor = 'error' | 'info' | 'success' | 'warning' | 'upgrade';

export const Placement = {
  Top: 'top',
  Right: 'right',
  Bottom: 'bottom',
  Left: 'left',
  TopLeft: 'topLeft',
  TopRight: 'topRight',
  BottomLeft: 'bottomLeft',
  BottomRight: 'bottomRight',
  RightTop: 'rightTop',
} as const;
export type PlacementType = (typeof Placement)[keyof typeof Placement];

export const Trigger = {
  Hover: 'hover',
  Focus: 'focus',
  Click: 'click',
  ContextMenu: 'contextmenu',
} as const;
export type TriggerType = (typeof Trigger)[keyof typeof Trigger];

export enum BittsFilterDataType {
  NUMBER = 'number',
  DATETIME = 'datetime',
  OBJECT = 'object',
}

export type BittsFilterValue = Nullable<
  string[] | [number, number] | (string | number)[]
>;
